.card{
    border-radius: $border-radius-base;
    background-color: $white-color;
    margin-bottom: 30px;

    .card-image{
        width: 100%;
        overflow: hidden;
        height: 260px;
        border-radius: $border-radius-base $border-radius-base 0 0;
        position: relative;
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        transform-style: preserve-3d;

        img {
            width: 100%;
        }
    }
    .filter{
        position: absolute;
        z-index: 2;
        background-color: rgba(0,0,0,.68);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;

        @include opacity(0);

        .btn{
            @include vertical-align();
        }
    }
    &:hover .filter{
        @include opacity(1);
    }
    .btn-hover{
        @include opacity(0);
    }
    &:hover .btn-hover{
        @include opacity(1);
    }
    .card-body{
        padding: 1px;
    }
    .card-header{
        padding: 5px;
        background-color: $white-color;
        border-bottom: none !important;

    }
    .card-category,
    label{
        font-size: $font-size-base;
        font-weight: $font-weight-normal;
        color: $dark-gray;
        margin-bottom: 0px;

        i{
            font-size: $font-paragraph;
        }
    }

    label{
        font-size: $font-size-small;
        margin-bottom: 5px;
        text-transform: uppercase;
    }

    .card-title{
        margin: $none;
        color: $black-color;
        font-weight: $font-weight-light;
    }
    .avatar{
        width: 30px;
        height: 30px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 5px;
    }
    .description{
        font-size: $font-size-base;
        color: #333;
    }
    .card-footer{
        padding-top: 0;
        background-color: $transparent-bg;
        line-height: 30px;
        border-top: none !important;
        font-size: 14px;

        .legend{
            padding: 5px 0;
        }

        hr{
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
    .stats{
        color: #a9a9a9;
    }
    .card-footer div{
        display: inline-block;
    }

    .author{
        font-size: $font-size-small;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }
    .author i{
        font-size: $font-size-base;
    }
    h6{
        font-size: $font-size-small;
        margin: 0;
    }
    &.card-separator:after{
        height: 100%;
        right: -15px;
        top: 0;
        width: 1px;
        background-color: $medium-gray;
        card-body: "";
        position: absolute;
    }

    .ct-chart{
        margin: 30px 0 30px;
        height: 245px;
    }

    .ct-label{
        font-size: 1rem !important;
    }

    .table{
        tbody td:first-child,
        thead th:first-child{
            padding-left: 2px;
        }

        tbody td:last-child,
        thead th:last-child{
            padding-right: 2px;
        }
    }

    .alert{
        border-radius: $border-radius-base;
        position: relative;

        &.alert-with-icon{
            padding-left: 65px;
        }
    }
}



.card-stats{
    .card-body{
        padding: 15px 15px 0px;
        color: $black-color;

        .numbers{
            font-size: 1.8rem;
            text-align: right;

            p{
                margin-bottom: 0;
            }
        }
    }
    .card-footer{
        padding: 0px 15px 10px 15px;


    }
    .icon-big {
        font-size: 3em;
        min-height: 64px;

        i{
            font-weight: 700;
            line-height: 59px;
        }
    }


}

.card-user{
    .card-image{
        height: 110px;
    }
    .card-image-plain{
        height: 0;
        margin-top: 110px;
    }
    .author{
        text-align: center;
        text-transform: none;
        margin-top: -70px;
    }
    .avatar{
        width: 124px;
        height: 124px;
        border: 5px solid #FFFFFF;
        position: relative;
        margin-bottom: 15px;

        &.border-gray{
            border-color: #EEEEEE;
        }
    }
    .title{
        line-height: 24px;
    }
    .card-body{
        min-height: 240px;
    }
}

.card-user,
.card-price{
    .card-footer{
        padding: 5px 15px 10px;
    }
    hr{
        margin: 5px 15px;
    }
}
.card-plain{
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;

    .card-image{
        border-radius: 4px;
    }
}

.card.card-plain{
    border: none !important;

    .card-header{
        background-color: transparent !important;
    }
}

.btn-primary, .btn-primary:focus, .btn-primary:hover, .btn-primary:visited, .btn-primary:active{
    color: #f1f1f1;
    background: #242424;
    padding: 5px;
    font-size: 14px;
    border: 1px solid #000;
    font-weight: bold;
    display: inline-block;
    outline: none;
}

.title-header {
    background-color: rgba(255, 255, 255, .15);  
    backdrop-filter: blur(5px);
    border-radius: 5px;
    display: block;
    padding: 10px;
    letter-spacing: 0.03em;
}

.score-box {
    background-color: #121212;
    border-radius: 3px;
    border: 2px solid #242424;
    text-align: center;
    font-weight: bold;
    width: 35px;
    padding: 0px;
}

.three-box {
    text-align: right;
    background: #121212;
    border-left: 2px #121212 solid;
    border-top: 2px solid #121212;
}

.winner-header {
    background: #333;
    text-align: center;
    padding: 5px;
    color: gold;
    letter-spacing: 0.1em;
    font-weight: bold;
}

.pbp {
    padding: 2px;
    display: block;
    text-transform: math-auto;
    color: white;
    background: #6e6d6b;
    font-weight: 200;
    font-size: 6pt;
    line-height: 8px;
    margin: 1px -2px;
    height: 20px;
    overflow: hidden;
    letter-spacing: -0.05em;
    position:absolute;
    left:0;
    width:100%;
    padding-right:60px;
    border-radius:0px;
}
.pbp-clock{
    position:absolute;
    right:0;
    min-width:50px;
    height:20px;
    text-wrap:pretty;
    overflow:hidden;
    border-radius:0px;
}

.favorite-header {
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    overflow: hidden;
    text-shadow: 1px 1px #242424;
}

.favorite-score {
    background: #242424;
    border-radius: 5px;
    display: inline-block;
    padding: 3px;
    line-height: 15px;
    font-size: 15px;
    width: 30px;
    top: -2px;
    margin-left: 3px;
    position: relative;
    border-top: 0.01em solid #6e6d6b;
    border-left: 0.01em solid #6e6d6b;
}

.favorite-winner {
    border: 2px solid gold;
    border-radius: 5px;
}

.shimmer {
    mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/350% 100%;
    animation: shimmer 2.5s infinite;
  }
  
  @keyframes shimmer {
    100% {
      mask-position: left
    }
  }

  .flip-container {
    perspective: 1000px;
    height: 60px; /* Adjust as needed */
  }
  
  .flip-card {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.5s;
    transform-style: preserve-3d;
  }
  
  .flip-card.flipped {
    transform: rotateX(180deg);
  }
  
  .flip-front, .flip-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .flip-back {
    transform: rotateX(180deg);
  }

  .player-img{
    float:left;
    height:40px;
    width:30px;
  }

  .player-name {
    letter-spacing: 0.05em;
    font-weight: 600;
    font-size: 9pt;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 2px;
    padding-left: 5px;
  }

  .player-number {
    position: absolute;
    font-weight: bold;
    left: 0px;
    font-size: 6px;
    top: 27px;
    padding: 2px;
    color: #ffffff;
    border-top-right-radius: 5px;
    background: #6c6c6c;
  }

  .player-container{
    width: 160px;
    padding-right: 5px;
    display: block;
    position: relative;
  }

  .plus-one {
    position: absolute;
    left: 50%;
    top: -10px;
    transform: translateX(-50%);
    color: white;
    font-size: 12px;
    font-weight: bold;
    opacity: 1;
    animation: fadeUp 2s ease-out;
}

@keyframes fadeUp {
    0% {
    opacity: 1;
    transform: translate(-50%, 0);
    }
    100% {
    opacity: 0;
    transform: translate(-50%, -20px);
    }
}

.first-three-trophy {
    background: black;
    font-size: 12px;
    padding: 2px;
    display: inline-block;
    padding-right: 4px;
    border: 1px solid #ffc008;
}